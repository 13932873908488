@import './_SubmissionForm.scss';

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  h1 {
    text-align: center;
    color: #333;
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  
  button {
    background-color: #1976d2;
    color: #fff;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  
    &:hover {
      background-color: #1565c0;
    }
  }

  @keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  