.upload-button {
    background-color: #1976d2;
    color: #fff;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: #1565c0;
    }
  }
  
  .dropzoneContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border: 2px dashed #1976d2;
    border-radius: 4px;
    background-color: #f0f8ff;
    cursor: pointer;
    outline: none;
    margin: 1rem 0;
  }